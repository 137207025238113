<template>
  <div>
    <div v-if="item && item.shipmentFile">
    <v-chip
      small
      class="primaryText--text"
      outlined
      style="border: none; font-size: 11px"
    >
      <v-avatar size="10"
        :color="item.movementType == 'EXPORT' ? 'deep-orange' : 'blue'"
        left
      >
        <h4 style="color: white; font-size: 11px">
          {{ item.movementType.charAt(0) }}
        </h4>
      </v-avatar>
      {{ item.shipmentFile.fileNumber }}
    </v-chip>
  </div>
  <span v-else-if="value">{{ value }}</span>
  </div>
 
</template>
<script>
export default {
  data: () => ({
    item: null,
    value: null,
  }),
  mounted() {
    this.item = {
      shipmentFile: this.params.shipmentFile,
      movementType: this.params.movementType,
    };
    if(!this.params.shipmentFile){
      this.value = this.params.value
    }
  },
  methods: {},
};
</script>